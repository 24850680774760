body {
	font-family: "Source Sans Pro" !important;
	background-color: #fffdf7 !important;
	color: #696969 !important;
	overflow-y: visible !important;
}

.show-scroll-when-scrolling {
	overflow-y: hidden;
}

.show-scroll-when-scrolling:hover {
	overflow-y: scroll;
}

.hide-scroll-bar::-webkit-scrollbar {
	display: none;
}

.hide-scroll-bar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.new-font {
	font-family: "Source Sans Pro" !important;
}

#easy-pricing-icon svg {
	fill: rgba(14, 135, 246, 1) !important;
}

#facts_video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
